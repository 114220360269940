export const CloseIcon = ({ ...props }) => {
  return (
    <svg width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.67 5.061c-.222.081-.42.248-.542.455-.092.156-.108.229-.107.484 0 .212.022.341.074.44.04.077 1.291 1.36 2.779 2.85L10.579 12l-2.705 2.71c-1.488 1.49-2.739 2.773-2.779 2.85-.052.099-.074.228-.074.44-.001.258.015.327.112.492.13.222.369.409.603.473.202.054.53.024.709-.066.074-.037 1.355-1.285 2.845-2.773L12 13.421l2.71 2.705c1.49 1.488 2.773 2.739 2.85 2.779.099.052.228.074.44.074.258.001.327-.015.492-.112.222-.13.409-.369.473-.603.054-.202.024-.53-.066-.709-.037-.074-1.285-1.355-2.773-2.845L13.421 12l2.705-2.71c1.488-1.49 2.739-2.773 2.779-2.85.052-.099.074-.228.074-.44.001-.255-.015-.328-.107-.484a1.003 1.003 0 0 0-1.215-.451c-.179.06-.447.315-2.927 2.789L12 10.578 9.27 7.854C6.79 5.38 6.522 5.125 6.343 5.065a1.015 1.015 0 0 0-.673-.004" />
    </svg>
  );
};




