import React, {useState} from "react";
import {Stack, Typography} from "@mui/material";
import {Star, Play, Plus} from "../../assets/files";
import styles from "./LiveDescription.module.css";
import CustomChip from "../Chip/Chip";
import {Button} from "../index";
import {IData, IRelated} from "../Cards/types";
import {AppDispatch} from "../../store";
import {useDispatch} from "react-redux";
import Keys from "../../constants/helper";
import {getContentDetails} from "../../services/contentAPI";
import {openVideoPlayer} from "../../store/player/playerSlice";
import {addToList} from "../../services/userAPI";
import {useTranslation} from "react-i18next";
import {formatToOneDecimal} from "../../utils";


interface IProps {
    data: IRelated | IData | null;
    details?: boolean;
}

const LiveDescription: React.FC<IProps> = (props) => {
    const {data} = props;
    const {t} = useTranslation("translations");
    const genreArray = data?.genders && data?.genders.split(",") || [];
    const profileId = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const dispatch: AppDispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false)
    const handlePlay = async () => {
        setIsLoader(true);
        if (profileId && data?.id) {
            try {
                const res = await getContentDetails(profileId, Number(data.id));
                if (res.video) {
                    dispatch(openVideoPlayer({
                        id: Number(data?.id),
                        title: res?.title || "",
                        type: "TV",
                        videoData: res.video,
                    }));
                }
            } catch (error) {
                console.error("Error fetching content details:", error);
            } finally {
                setIsLoader(false);
            }
        } else {
            console.warn("Profile ID or data ID is missing.");
        }
    };

    const handleFavorite = async () => {
        try {
            if (profileId && data?.id) await addToList(profileId, {content: data.id});
        } catch (error) {
            console.error("Failed to add to list", error);
        }
    };

    return (
        <div className={styles.box}>
            <div>
                <img src={data?.backdrop_path} alt={"backdrop"} loading={"lazy"}/>
            </div>
            <div className={styles.content}>
                <Typography variant={"subtitle1"}>{data?.title}</Typography>
                <Stack direction="row" spacing={1} alignItems={"center"} gap={1}>
                    {data?.rating && <span className={styles.rating}><Star/> {formatToOneDecimal(data?.rating)}</span>}
                    {data?.duration && <CustomChip label={data.duration} color="default"/>}
                    {data?.classification && <CustomChip label={data.classification} color="primary"/>}
                </Stack>
                <Stack direction="row" spacing={2} mt={6} mb={6}>
                    <Button
                        isLoading={isLoader}
                        color="primary"
                        variant="contained"
                        startIcon={<Play/>}
                        onClick={handlePlay}
                    >
                        {t("Buttons.watch")}
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        startIcon={<Plus/>}
                        fullWidth
                        sx={{maxWidth: 300, justifyContent: "flex-start"}}
                        onClick={handleFavorite}
                    >
                        {t("Buttons.addToMyList")}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={1} alignItems={"center"} mt={2} flexWrap={"wrap"} gap={1}>
                    {genreArray.map((genre: string) => (
                        <CustomChip key={genre} label={genre} color="default"/>
                    ))}
                </Stack>
                <Typography variant={"body1"} mt={2} pr={2}>{data?.synopsis}</Typography>

            </div>
        </div>
    );
};

export default LiveDescription;
