import { CustomContentProps, SnackbarOrigin } from "notistack";

export const snackbarPosBottomRight: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};

export interface ICustomSnackbarContent {
  type?: "success" | "error" | any;
  message: string;
  title?: string;
  id?: number | string;
  onClose: () => void;
}

export interface ReportCompleteProps extends CustomContentProps {
  type: "success" | "error";
  title?: string
}
