import React, { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { closeSnackbar, SnackbarContent } from "notistack";
import { ICustomSnackbarContent, ReportCompleteProps } from "./types"; // Ensure these types are correct
import styles from "./Notification.module.css";
import {Alarm, CloseIcon} from "../../assets/files"; // Adjust import path if needed

const CustomSnackbarContent: FC<ICustomSnackbarContent> = ({ message, onClose, type }) => {
  return (
    <SnackbarContent
      className={`${styles.snackbar} ${type === "success" ? styles.success : styles.error}`} // Adjust to show error style if needed
    >
      <Box className={styles.snackbarContent}>
        <Alarm className={styles.alarm} stroke={type === "success" ? "#02B151" : ""} />
        <Box className={styles.snackbarRight}>
          <Typography variant={"h2"}>{type === "success" ? "Well done! " : "Ouch!"}</Typography>
          <Typography variant={"h4"}>{message}</Typography>
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
};

const CustomSnackBar = React.forwardRef<HTMLDivElement, ReportCompleteProps>(
  (props, ref) => {
    const { id, message, variant, title } = props;

    return (
      <SnackbarContent ref={ref} role="alert">
        <CustomSnackbarContent
          message={message as string}
          type={variant} // Pass the variant as type
          title={title}
          onClose={() => closeSnackbar(id)}
        />
      </SnackbarContent>
    );
  }
);

export default CustomSnackBar;
