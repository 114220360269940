import React, {useEffect, useState} from "react";
import {
    AppBar,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    Stack,
    SvgIcon,
    Typography,
    Drawer,
    Box, Popover,
} from "@mui/material";
import {
    ActiveLine,
    BurgerClose,
    BurgerMenu,
    BurgerSearch,
    BurgerSettings,
    Check, Chevron,
    EditIcon, Logo,
    LogoKids, PrevIcon
} from "../../assets/files";
import styles from "./AsideModile.module.css";
import helper from "../../constants/helper";
import {useNavData, useNavKidsData} from "../Aside/IAside";
import {useLocation, useNavigate} from "react-router-dom";
import {Button} from "../index";
import Avatar from "../../assets/images/Avatar.png";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {routes} from "../../routes/routes";
import {resetProfile} from "../../store/profile/profileSlice";
import Keys from "../../constants/helper";
import {useTranslation} from "react-i18next";
import {setProfileID, setRole, setUser} from "../../store/reducer";
import {IProfile} from "../Aside/Aside";
import {fetchUserList} from "../../store/profile/thunks";

interface IProps {
    withoutMenu?: boolean
}

const AsideMobile = ({withoutMenu}: IProps) => {
    const {t} = useTranslation("translations");
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch: AppDispatch = useDispatch()
    const role = localStorage.getItem(helper.EPIC_USER_ROLE);
    const navData = useNavData();
    const navDataKIDS = useNavKidsData();
    const [menuOpen, setMenuOpen] = useState(false);
    const {isFetched, list} = useSelector((state: RootState) => state.profile);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const openPopup = Boolean(anchorEl);
    const dataToMap = role === "KIDS" ? navDataKIDS : navData;
    const profileID = localStorage.getItem(helper.EPIC_PROFILE_ID);
    const profileData = localStorage.getItem(helper.EPIC_USER_DATA);
    const parsedUserData = profileData ? JSON.parse(profileData) : null;

    useEffect(() => {
        if (isFetched && !list) {
            dispatch(fetchUserList());
        }
    }, [isFetched, menuOpen, profileID]);


    const renderTitle = dataToMap.find((e) => e.path === location.pathname)?.label || ""

    const toggleMenu = () => {
        if(!withoutMenu) {
            !list.length && dispatch(fetchUserList());
            setMenuOpen(!menuOpen)
        } else {
            window.history.back()
        }

    };

    const handleChangeLink = (path?: string) => {
        if (path) {
            navigate(path);
            setMenuOpen(false); // Close menu after navigation
        }
    };

    const handleNavigateToEdit = () => {
        navigate(routes.profile)
        dispatch(resetProfile())
        localStorage.removeItem(Keys.EPIC_PROFILE_ID);
        localStorage.removeItem(Keys.EPIC_USER_ROLE);
    }

    const handleChangeProfile = (e: IProfile) => {
        if (e) {
            console.log("e", e)
            dispatch(setProfileID(e?.id.toString()))
            dispatch(setUser(e))
            dispatch(setRole(e.is_kids ? "KIDS" : "USER"))
            window.location.reload();
        }

    }
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl === event.currentTarget) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    return (
        <>

            <AppBar position="fixed" className={styles.header}>
                <IconButton
                    className={styles.icon}
                    color="primary"
                    onClick={toggleMenu}
                >
                    <SvgIcon component={withoutMenu ? PrevIcon : BurgerMenu}/>
                </IconButton>
                <Stack direction="row" justifyContent="center">
                    <Typography fontWeight={700}>{renderTitle}</Typography>
                </Stack>

                <IconButton className={styles.icon} color="primary" onClick={() => navigate(routes.search)}>
                    <SvgIcon component={BurgerSearch}/>
                </IconButton>
            </AppBar>

            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={toggleMenu}
                classes={{paper: styles.drawer}}>
                <Box className={styles.menu}>
                    <AppBar position="fixed" className={styles.header}>
                        <IconButton
                            className={styles.icon}
                            color="primary"
                            onClick={toggleMenu}
                        >
                            <SvgIcon component={BurgerClose}/>
                        </IconButton>
                        <Stack direction="row" justifyContent="center">
                            <Typography fontWeight={700}>
                                <IconButton>{role == "KIDS" ? <LogoKids size={145} height={48}/> :
                                    <Logo size={145} height={48}/>}
                                </IconButton>
                            </Typography>
                        </Stack>
                        <IconButton className={styles.icon} color="primary" onClick={() => {
                            toggleMenu()
                            navigate(routes.settings)
                        }}>
                            <SvgIcon component={BurgerSettings}/>
                        </IconButton>
                    </AppBar>
                    <div className={styles.profile}>
                        <img src={parsedUserData?.avatar?.file || Avatar} alt={"file"} loading={"lazy"}/>
                        <Stack direction={"row"} alignItems={"center"} gap={.5} mt={1} onClick={handleOpenPopover}>
                            <ListItemText
                                className={styles.text}
                                primary={parsedUserData?.name}
                            />
                            <Chevron rotate={openPopup ? 180 : 0}/></Stack>

                    </div>
                    {dataToMap.slice(1, 8).map((item, index) => (
                        <div key={item.key}>
                            <ListItem>
                                <Button
                                    onClick={() => handleChangeLink(item.path)}
                                    fullWidth
                                    startIcon={
                                        <Stack alignItems={"center"} gap={.5}>
                                            <item.icon/>
                                            {location.pathname === item.path && <ActiveLine/>}
                                        </Stack>
                                    }
                                    color={location.pathname === item.path ? "info" : "secondary"}
                                    variant={"outlined"}>
                                    {item.label}
                                </Button>

                            </ListItem>
                            {index === navData.length - 2 && (
                                <Divider style={{opacity: 0.16}}/>
                            )}
                        </div>
                    ))}
                </Box>
                <Popover
                    onClose={() => setAnchorEl(null)}
                    open={openPopup}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}>
                    <div className={styles.popoverAudio}>
                        {list.map((e) => (
                            <div key={e.id} className={e.id === parsedUserData?.id ? styles.active : ""}
                                 onClick={() => handleChangeProfile(e)}>
                                <img src={e.avatar?.file || Avatar} alt={"file"}/>
                                <Typography variant={"h5"}>{e.name}</Typography>
                                {e.id === parsedUserData?.id && <Check/>}
                            </div>
                        ))}
                        <div className={styles.edit} onClick={handleNavigateToEdit}>
                            <div><EditIcon/></div>
                            <Typography variant={"h5"}>{t("Authorization.editProfiles")}</Typography>
                        </div>
                    </div>
                </Popover>
            </Drawer>
        </>
    );
};

export default AsideMobile;
