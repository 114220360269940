import React from "react";

interface IProps {
    size?: number;
    rotate?: number;
    onClick?: () => void;
}

export const Chevron: React.FC<IProps> = ({size = 20,  rotate = 0, onClick}) => {
    return (
        <svg
            onClick={onClick}
            width={size}
            height={size + 1}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{transform: `rotate(${rotate}deg)`, transition: "transform 0.3s"}}
        >
            <path
                d="M5 8L10 13L15 8"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
