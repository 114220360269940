import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
import "./styles/fonts.css";
import MainRouter from "./routes/MainRouter";
import {Provider} from "react-redux";
import {store} from "./store";
import {StyledEngineProvider} from "@mui/material";
import Theme from "./Theme";
import {I18nextProvider, initReactI18next} from "react-i18next";
import i18next from "i18next";
import EN from "./assets/translations/en/en.json";
import ES from "./assets/translations/es/es.json";
import Keys from "./constants/helper";
import {SnackbarProvider} from "notistack";
import CustomSnackBar from "./components/Notification/Notification";

type Language = "es" | "en";

const userData = localStorage.getItem(Keys.EPIC_USER_DATA);
const parsedData = userData ? JSON.parse(userData) : {};
const language: Language = parsedData.language === "en" || parsedData.language === "es"
    ? parsedData.language
    : "es";

i18next.use(initReactI18next).init({
    interpolation: { escapeValue: false },
        lng: language, // language to use
        resources: {
            en: { translations: EN },
            es: { translations: ES },
        },
        ns: ["translations"], // define the namespace
        defaultNS: "translations",
    });

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}></I18nextProvider>
        <SnackbarProvider
            Components={{
                error: CustomSnackBar,
                success: CustomSnackBar,
            }}
            autoHideDuration={5000}
        >
        <Theme>
            <StyledEngineProvider injectFirst>
                <MainRouter/>
            </StyledEngineProvider>
        </Theme>
        </SnackbarProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
